<template>
  <div id="upload-view">
    <aidaUploader v-model="attach" :readonly="false" :bizType="bizType" :isSingleFile="false" :maxSize="100">
    </aidaUploader>
    <div class="tool">
      <el-button type="primary" @click="onSave">保存</el-button>
    </div>
  </div>
</template>

<script>
import aidaUploader from "@/component/aidaUploader.vue";
import { cosApi } from "@/api/cosApi.js";
import msgLogger from "@/util/msgLogger";
export default {
  components: {
    aidaUploader,
  },
  data() {
    return {
      attach: [],
      bizType: "base",
    };
  },
  mounted() {},
  destroyed() {},
  methods: {
    onSave() {
      console.log(this.attach);
      cosApi.batchAddCos(this.attach).then((ret) => {
        console.log("batch add", ret);
      });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
#upload-view {
  .tool {
    margin: 20px;
  }
}
</style>
